import React from "react";
import { Footer } from "../Footer";
import { Header } from "../Header";
import Accordion from 'react-bootstrap/Accordion';
import { useEffect } from "react";
import { Link } from 'react-router-dom';


function Eventfather() {
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />

      <section
        className=" Eventfather mt-5"
        style={{ minHeight: "100vh" }}>
      </section>

      <section className="mt-5 mb-5 " style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12">
              <div className="card p-3 " style={{ backgroundColor: "#1A1E27" }}>
                <div className="row d-flex justify-content-center">
                  <div className="col-md-4">
                    <h4 className="text-white text-center fw-bold border-bottom border-danger border-3"> FATHER DAUGHTER DANCE</h4>
                  </div>

                </div>
                <p className="text-white text-center ">
                  Love Without Reason is proud to present the Father Daughter
                  Dance on Friday, February 9, 2024.
                </p>

                <p className="text-white text-center ">
                  Spend the Friday before Valentine's day with your daughter at
                  the annual Father Daughter Dance. No matter what the age, this
                  event is designed for fathers and father figures with their
                  daughters. Join us for an evening of memories on February 9,
                  2024, all to support a great cause through Love Without Reason
                  and the work in building and strengthening families.

                </p>
                {/* <Link to='/FatherDaughterDanceGallery' className='btn btn-danger px-4 mx-auto'>Gallery</Link> */}
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center mb-5">
            <div className='col-md-10 text-start mt-3 mb-5'>
              <h3 className='fw-bold text-danger mt-5'>FREQUENTLY ASKED QUESTIONS</h3>
              <div className='col-md-12 text-start mt-4'>
                <Accordion defaultActiveKey="0" >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header ><b> What is the Father Daughter Dance? </b></Accordion.Header>
                    <Accordion.Body>
                      The Father Daughter Dance is an event for dads and father
                      figures to spend a special evening with their daughter(s).
                      All father figures are welcome, including stepfathers,
                      grandfathers, uncles, brothers, foster fathers and other
                      significant male role models. This event is open for all
                      dads and their daughter(s).
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header><b>What should we wear? </b></Accordion.Header>
                    <Accordion.Body>
                      This is an evening about the girls! If your daughter likes
                      to dress up and wear something formal, then this is the
                      place to do so. If your daughter would rather be casual and
                      wear the outfit of her choice, then this is the place to do
                      so. This is not an event about what you are wearing; this is
                      about spending time with your dad or father figure.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header><b>What happens at the Father Daughter Dance?</b></Accordion.Header>
                    <Accordion.Body>
                      A lot of fun of course! There will be a DJ to keep the girls
                      and their dad dancing the night away. Get fueled up with
                      refreshments. There will also be a photo booth for pictures
                      to take.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header><b> Who benefits from the Father Daughter Dance?</b></Accordion.Header>
                    <Accordion.Body>
                      All proceeds from the event go toward Love Without Reason
                      and their work in strengthening and building families. Learn
                      more on What We Do.This event is hosted by Love Without
                      Reason.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="4">
                    <Accordion.Header><b> Refunds and Waivers?</b></Accordion.Header>
                    <Accordion.Body>
                      All tickets purchased for People Not Products are final. Refunds or exchanges will not be issued, but will be donated to benefit Love Without Reason. In this season of COVID uncertainty, please be sure to take all necessary precautions to be safe, and to keep others safe. The event will be photographed and by purchasing tickets you are giving Love Without Reason the permission to use those pictures on social media or other media.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section >
      <Footer />
    </>
  );
}

export { Eventfather };
