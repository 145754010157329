import React from "react";
import "../../index.css";
import axios from 'axios';
import slide1 from '../../assests/newslider1.png';
import slide2 from '../../assests/newslider2.png';
import slide3 from '../../assests/newslider3.png';
import slide4 from '../../assests/newslider4.png';
import wwd11 from '../../assests/homewwd1.png';
import wwd12 from '../../assests/homewwd2.png';
import wwd13 from '../../assests/homewwd3.png';
import wwd14 from '../../assests/homewwd4.png';
import kenyacamp from '../../assests/Kenyacamposter.jpg';
import philips from '../../assests/homephilips1.png';
import card_1 from '../../assests/card_1.png';
import card_2 from '../../assests/card_2.png';
import card_3 from '../../assests/card_3.png';
import card_4 from '../../assests/card_4.png';
import card_5 from '../../assests/card_5.png';
import card_6 from '../../assests/card_6.png';
import news from '../../assests/Group 1867.png';
import logo from '../../assests/lwror.jpeg';
import line from '../../assests/Group 1870 line2.png';
import share from '../../assests/Vector.png';
import news1 from '../../assests/news1.png';
import news2 from '../../assests/news2.png';
import pdf1 from '../../assests/2024 February.pdf';
import pdf2 from '../../assests/newsletter5.pdf';
import pdf3 from '../../assests/2023 November.pdf';
import milestone from '../../assests/1000thsurgerymilestone.jpeg';


import instagram from "../../assests/LWR insta banner 3-01.png";
import { useEffect, useState } from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Link } from "react-router-dom";

function Home({ surgeriesCounter, countryCount, campCount }) {
  const [showPopup, setShowPopup] = useState(true);

  // Function to close the pop-up
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  // Function to open gmail
  const openGmail = () => {
    const email = "EVENTS@LOVEWITHOUTREASON.ORG";
    const subject = encodeURIComponent("RSVP for 1000 smiles milestone celebration");
    const body = encodeURIComponent("");
    const gmailURL = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`;
    window.open(gmailURL, '_blank');
  };

  const handleClick = async () => {
    try {
      const response = await fetch('https://ipinfo.io?token=f667c2c73c3553');
      const data = await response.json();

      if (data.country === 'IN') {
        window.location.href = '/DonateIndia'; // Redirect to Donations component for users from India
      } else {
        window.location.href = '/Donate'; // Redirect to Donations component for users from India

      }
    } catch (error) {
      console.error('Error fetching IP information:', error);
      // Handle error, you may want to redirect to a default link or display an error message
    }
  };

  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  const [surgeriesCount, setSurgeriesCount] = useState(0);
  const [campsCounter, setCampsCounter] = useState(0);
  const [countriesCounter, setCountriesCounter] = useState(0);
  const [volunteersCounter, setVolunteersCounter] = useState(0);
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const openPDF1 = () => {
    window.open(pdf1, '_blank');
  };
  const openPDF2 = () => {
    window.open(pdf2, '_blank');
  };
  const openPDF3 = () => {
    window.open(pdf3, '_blank');
  };
  useEffect(() => {
    const surgeriesInterval = setInterval(() => {
      if (surgeriesCount < 1012) {
        setSurgeriesCount(surgeriesCount + 1);
      } else {
        clearInterval(surgeriesInterval);
      }
    }, -10);

    const campsInterval = setInterval(() => {
      if (campsCounter < 75) {
        setCampsCounter(campsCounter + 1);
      } else {
        clearInterval(campsInterval);
      }
    }, 10);

    const countriesInterval = setInterval(() => {
      if (countriesCounter < 7) {
        setCountriesCounter(countriesCounter + 1);
      } else {
        clearInterval(countriesInterval);
      }
    }, 10);

    const volunteersInterval = setInterval(() => {
      if (volunteersCounter < 600) {
        setVolunteersCounter(volunteersCounter + 1);
      } else {
        clearInterval(volunteersInterval);
      }
    }, 10);


    return () => {
      clearInterval(surgeriesInterval);
      clearInterval(campsInterval);
      clearInterval(countriesInterval);
      clearInterval(volunteersInterval);
    };
  }, [surgeriesCounter, campsCounter, countriesCounter, volunteersCounter]);

  const [mailData, setEmailMailData] = useState({ email: "" });
  const userDetails = (e) => {
    setEmailMailData({ email: e.target.value });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!mailData.email) {
      alert('Email Is Required');
      return;
    }

    const payload = {
      service_id: "service_tt2kz7w",
      template_id: "template_42v7zzh",
      user_id: "ppekGscEBiOpBosKz",
      template_params: {
        email: mailData.email,
      },
    };

    try {
      const res = await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        JSON.stringify(payload),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data === "OK") {
        setSubscribed(true);
        const modal = new window.bootstrap.Modal(document.getElementById('exampleModalhome'));
        modal.show();
      }
    }
    catch (err) {
    }
  };




  return (
    <>
      <Header />
      {showPopup && (
        <div className="popup-container position-fixed top-0 left-0 d-flex justify-content-center align-items-center w-100 h-100" style={{zIndex:"9999",backgroundColor:"rgba(0, 0, 0, 0.5)"}}>
          <div className="popup-content" >
            <button className="btn-close" onClick={handleClosePopup}></button>
            <a  onClick={openGmail}><img style={{width:"350px",cursor:"pointer"}} src={milestone} className="popup-image img-fluid" alt="Pop-up Image" /></a>
          </div>
        </div>
      )}

      <section className="homepageslider" style={{ backgroundColor: "#212129", minHeight: '100vh' }}>
        <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
          <ol class="carousel-indicators rounded-circle">
            <li data-bs-target="#carouselExampleSlidesOnly" data-bs-slide-to="0" class="active"></li>
            <li data-bs-target="#carouselExampleSlidesOnly" data-bs-slide-to="1"></li>
            <li data-bs-target="#carouselExampleSlidesOnly" data-bs-slide-to="2"></li>
            <li data-bs-target="#carouselExampleSlidesOnly" data-bs-slide-to="3"></li>
          </ol>
          <div class="carousel-inner">


            <div class="carousel-item active">
              <div class="container">
                <div class="row align-items-center justify-content-between mt-5" style={{ marginTop: '5%' }} >
                  <div class="col-md-5 mt-5">
                    <div class="carousel-content">
                      <h2 className='text-white sedanfont display-5'>UNTIL THE WORLD IS <br /><span className="sedanfont display-5 text-white" style={{ color: "#ED1C24" }}>  CLEFT-FREE </span></h2>
                      <div className="d-flex">
                        <Link onClick={handleClick} className='btn btn-light mt-3 fw-bold ps-3 pe-3 m-1' style={{ borderRadius: "5px" }}>DONATE</Link>
                        {/* <Link to="/Kenya" className='btn btn-light mt-3 fw-bold ps-3 pe-3 ms-3 m-1' style={{ borderRadius: "5px" }}>Kenya Medical Camp</Link> */}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-7 text-end">
                    <img src={slide2} class="img-fluid" alt="Slide2" />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="container">
                <div class="row align-items-center justify-content-between mt-5 " style={{ marginTop: '5%' }} >
                  <div class="col-md-6">
                    <img src={slide3} class="img-fluid" alt="Slide3" />
                  </div>
                  <div class="col-md-5">
                    <div class="carousel-content">
                      <h2 className='text-white sedanfont display-5 text-start'>UNTIL THERE ARE <br /><span className="sedanfont display-5 text-white" style={{ color: "#ED1C24" }}>  NO-MORE SOLD </span></h2>
                      <Link onClick={handleClick} className='btn btn-light fw-bold ps-3 pe-3' style={{ borderRadius: "5px" }}>DONATE</Link>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="container">
                <div class="row align-items-center justify-content-between mt-5" style={{ marginTop: '5%' }}>
                  <div class="col-md-5">
                    <div class="carousel-content">
                      <h2 className='text-white mt-5 sedanfont display-5'>BECAUSE EVERY<br /><span className="sedanfont display-5 text-white" style={{ color: "#ED1C24" }}>  CHILD MATTERS</span></h2>
                      <Link onClick={handleClick} className='btn btn-light mt-3 fw-bolder ps-3 pe-3' style={{ borderRadius: "5px" }}>DONATE</Link>
                    </div>
                  </div>
                  <div class="col-md-7 text-end ">
                    <img src={slide4} class="img-fluid" alt="Slide4" />
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item ">
              <div class="container">
                <div class="row align-items-center justify-content-between mt-5" style={{ marginTop: '5%' }} >
                  <div class="col-md-5">
                    <img src={slide1} class="img-fluid" alt="Slide1" />
                  </div>
                  <div class="col-md-5 text-center">
                    <div class="carousel-content">
                      <h2 className='text-white sedanfont display-3 '>#PhilipsDream</h2>
                      <h4 className='text-white sedanfont display-6 ' style={{ color: "#ED1C24" }}>The Legacy Continues...</h4>
                      <Link to="/philip"> <h4 className=""><button className='btn btn-light mt-2 fw-bolder ps-3 pe-3 mb-3' style={{ borderRadius: "5px" }}>Read Philip's Dream</button></h4></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section style={{ minHeight: "25vh" }}>
        <div className="container">
          <div className="row mt-5 d-flex justify-content-center align-items-center">
           

            <div className="col-md-12">
              <p className="india-text">Love Without Reason prevents human trafficking by providing transformative cleft surgeries and fights sex trafficking through education, empowerment and innovative solutions. Over 1000 free cleft surgeries have been provided for individuals from 3 months of age to 80 years of age.  Over 6000 women in the red-light areas have received education and training, and over 300 women and children were rescued from sex trafficking.  Using innovative solutions such as the Set Free Pads, leadership training and conferences, and clean water wells provides holistic care to men, women and children.<br /><br />

                </p>
              {/* <div className="d-flex justify-content-evenly mt-3">
                <Link to='/About Us' className="btn btn-danger">About Us</Link>
                <Link to='/Kenya' className="btn btn-danger">Kenya Medical Camp</Link>

              </div> */}
            </div>

          </div>
        </div>

      </section>
      {/* section1 */}

      <section className="mt-5" style={{ minHeight: '100vh' }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="text-danger fw-bold mb-0">Discover Our Mission</h2>
            </div>
          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-md-5 mt-3">
              <Link className="text-decoration-none text-dark" to='/Craniofacial'> <div className="card  shadow-sm border p-3">
                <img className="card-img-top img-fluid" src={wwd11} alt="Craniofacial Surgery" />
                <div className="card-footer fw-bolder d-flex justify-content-between bg-white">
                  <p>
                    Craniofacial Surgery
                  </p>
                  <i className="fa-solid fa-arrow-right fs-4"></i>
                </div>
              </div></Link>
            </div>
            <div className="col-md-5 mt-3">
              <Link className="text-decoration-none text-dark" to='/InnovativeSolutions'>
                <div className="card  shadow-sm border p-3">
                  <img className="card-img-top img-fluid" src={wwd12} alt="Innovative Solutions" />
                  <div className="card-footer fw-bolder d-flex justify-content-between bg-white">
                    <p>
                      Innovative Solutions
                    </p>
                    <i className="fa-solid fa-arrow-right fs-4"></i>
                  </div>
                </div>
              </Link>
            </div>

          </div>
          <div className="row d-flex justify-content-center p-3">
            <div className="col-md-5 mt-3">
              <Link className="text-decoration-none text-dark" to='/AntiHumanTrafficking'>
                <div className="card shadow-sm border p-3">
                  <img className="card-img-top img-fluid" src={wwd13} alt="Anti-Human Trafficking" />
                  <div className="card-footer fw-bolder d-flex justify-content-between bg-white">
                    <p>
                      Anti-Human Trafficking
                    </p>
                    <i className="fa-solid fa-arrow-right fs-4"></i>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-md-5 mt-3">
              <Link className="text-decoration-none text-dark" to='/InnovativeSolutions'>
                <div className="card  shadow-sm border p-3">
                  <img className="card-img-top img-fluid" src={wwd14} alt="Set Free Pads" />
                  <div className="card-footer fw-bolder d-flex justify-content-between bg-white">
                    <p>
                      Set Free Pads
                    </p>
                    <i className="fa-solid fa-arrow-right fs-4"></i>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section >



      {/* <section className="philipshomebg" style={{ minHeight: '100vh' }}>
        <div className="container  ">
          <div className="row d-flex justify-content-center align-items-center p-5">
            <div className="col-md-5 mt-5">
              <img src={philips} className="img-fluid" />
            </div>
            <div className="col-md-7 text-white mt-5">
              <h2 className="fw-bolder">Story behind Love Without Reason <br />”The pain that made us to give life to all childrens”</h2>
              <p>Seated on a couch one December afternoon in 2006, feeling sorry for myself for having lost a great job, I began to question God. What is the purpose of my life?</p>
              <p>Why do I even exist?</p>
              <p>I am a failure and a shame to my family and to my community.</p>
              <p>Why am I alive? The easiest thing would be to just end it right here.</p>
              <p className="fw-bolder">Santhosh Mathews <span className="text-danger ms-2">Founder & CEO</span></p>
              <hr className="border border-2 border-white" />
              <div className='row d-flex justify-content-between'>
                <div className='col-md-5'>
                  <Link to='/philip' className="text-decoration-none"> <h4 className="text-white fw-bold mb-4">Read Philip's Story <span className="fa-solid fa-arrow-right ms-2 fs-4"></span></h4></Link>
                </div>
                <div className='col-md-5'><Link to='/LWRstory' className="text-decoration-none"> <h4 className="text-danger fw-bold mb-4">Read Full Story <span className="fa-solid fa-arrow-right ms-2 fs-4"></span></h4>
                </Link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section> */}




      <section className="global-banner" style={{ minHeight: "100vh" }}>
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-12 text-center  mt-5">
              <h2 class=" text-white fw-bold mb-0">GLOBAL IMPACT</h2>
              <img className=" img-fluid" src={line} />
            </div>
          </div>
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-md-4  text-white mt-5 text-start">
              <div className="text-start mt-4">
                <h1 className="fw-bold"><br></br></h1>
                <h5 className="fw-bold text-uppercase"><br></br></h5>
              </div>
              <div className="text-start mt-4 ">
                <h1 className="fw-bold">300+</h1>
                <h5 className="fw-bold  text-uppercase">Women Rescued</h5>
              </div>
              <div className="text-start mt-4">
                <h1 className="fw-bold">1000+</h1>
                <h5 className="fw-bold text-uppercase">Set Free Pads</h5>
              </div>
              <div className="text-start mt-4">
                <h1 className="fw-bold">20,000</h1>
                <h5 className="fw-bold text-uppercase">Educated Globally</h5>
              </div>
            </div>
            <div className="col-md-3 text-end text-white mt-5 pe-4">
              <div className="text-center">
                <h1 className="fw-bold text-end">{surgeriesCounter}</h1>
                <h5 className="fw-bold text-end">SURGERIES</h5>
              </div>
              <div className="text-center mt-4">
                <h1 className="fw-bold text-end">{campCount}</h1>
                <h5 className="fw-bold text-end">CAMPS</h5>
              </div>
              <div className="text-center mt-4">
                <h1 className="fw-bold text-end">{countryCount}</h1>
                <h5 className="fw-bold text-end">COUNTRIES</h5>
              </div>
              {/* <div className="text-center mt-4">
                <h1 className="fw-bold text-end">{volunteersCounter}+</h1>
                <h5 className="fw-bold text-end">VOLUNTEERS</h5>
              </div> */}

              <div className="text-center mt-4 mb-5">
                <h1 className="fw-bold text-end">05</h1>
                <h5 className="fw-bold text-end text-uppercase">
                  Water Wells
                </h5>
              </div>
              {/* <div className="text-start mt-4 mb-5">
                <h1 className="fw-bold text-end">300+</h1>
                <h5 className="fw-bold text-end text-uppercase">Women Rescued</h5>
              </div> */}
            </div>
          </div>
        </div>
      </section>


      {/* <section style={{ minHeight: "100vh" }}>
        <div className="container-fluid">

          <div className="row d-flex justify-content-center">
            <div className="col-md-5 text-center mt-5 ">
              <h2 className='text-dark fw-bold mb-0'>SUSAN'S BLOGS</h2>
              <img className="img-fluid" src={line} />
              <div class="card border-0 shadow-sm mt-3">
                <img class="card-image border-0" src={susan} alt="Susan's Blog" />
                <button class="btn border text-danger fw-bold">Read Blogs</button>
              </div>

            </div>
            <div className="col-md-5 text-center mt-5">
              <h2 className="text-dark fw-bold mb-0">NEWSLETTER</h2>
              <img className="img-fluid" src={line} alt="Divider Line" />
              <img className="img-fluid border-0 mt-3" src={news} alt="Susan's Blog" />
              <button class="btn border text-danger font-weight-bold mt-1">Read News</button>
            </div>
          </div> 
        </div>
      </section> */}

      <section className='mb-5 mt-5' style={{ minHeight: "75vh" }}>
        <div className="container p-4  rounded  linear-gradient ">
          <div className="row d-flex justify-content-center align-items-center  d-none d-md-block">
            <div className="col-md-4 mx-auto">
              <h3 className="fw-bolder text-white text-center">Subscribe to our Newsletter</h3>
              <div style={{ position: "relative" }}>

                <form onSubmit={sendEmail}>
                  <input class="form-control" type="search" placeholder="Enter your email address" aria-label="Search"
                    onChange={userDetails}
                    style={{ paddingRight: "3rem" }} />
                  <button class="btn btn btn-dark mt-1 mb-1 px-3" type="submit"
                    style={{ position: "absolute", left: "85%", transform: "translateX(-50%)", top: "0", bottom: "0" }}>Subscribe</button>
                  {subscribed && (
                    <div
                      className="modal fade show"
                      id="exampleModalhome"
                      tabIndex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-modal="true"
                      role="dialog"
                      style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-body">
                            <h5 className="modal-title fw-bolder text-danger" id="exampleModalLabel">LOVE WITHOUT REASON</h5>
                            <hr />
                            <div className="row d-flex justify-content-center">
                              <div className="col-md-12 fs-5">
                                Thank you for contacting Love Without Reason. We appreciate your kindness, generosity, and support. One of our team members will contact you as soon as possible.
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer border-0">
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => setSubscribed(false)}>Close</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-center d-block d-sm-none">
            <div className="col-md-12">
              <h3 className="fw-bolder text-white text-center">Subscribe to our Newsletter</h3>
            </div>
            <div className="col-md-5">
              <input type="email" placeholder="Enter your email address"
                onChange={(e) => {
                  userDetails(e);
                }}
                className="form-control" />
            </div>
            <div className="col-md-10 mt-2 mb-2 text-center">
              <button type="submit" onSubmit={sendEmail} className="btn btn-dark">Subscribe</button>
            </div>
          </div>

          <div className="row d-flex justify-content-evenly mt-3 ">
            <div className="col-md-4">
              <div className="" >
                <img src={card_4} className="card-img-top " alt="..." />
                <p className="text-danger fw-bolder mt-2" style={{ fontSize: "15px" }}>Newsletter • February 2024</p>
                <Link className="text-decoration-none" onClick={openPDF1}>
                  <h5 className="card-title text-start fw-bolder text-white">Love Never Fails <i class="fa-solid fa-arrow-right  float-end"></i></h5>
                </Link>
              </div>
            </div>

            <div className="col-md-4 ">
              <div className="" >
                <img src={card_5} className="card-img-top " alt="..." />
                <p className="text-danger fw-bolder mt-2" style={{ fontSize: "15px" }}>Newsletter • December 2023</p>
                <Link onClick={openPDF2} className="text-decoration-none">
                <h5 className="card-title text-start fw-bolder text-white">The Joy of a Smile  <i class="fa-solid fa-arrow-right  float-end"></i></h5>
                </Link>
              </div>
            </div>

            <div className="col-md-4  ">
              <div className="" >
                <img src={card_6} className="card-img-top " alt="..." />
                <p className="text-danger fw-bolder mt-2" style={{ fontSize: "15px" }}>Newsletter • November 2023</p>
                <Link onClick={openPDF3} className="text-decoration-none">
                <h5 className="card-title text-start fw-bolder text-white">Because Every Child Matters <i class="fa-solid fa-arrow-right  float-end"></i></h5>

                </Link>
              </div>
            </div>
          </div>
        </div>
      </section >





      < Footer />
    </>
  );
}
export { Home };
